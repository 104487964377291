export const HOME = "/";
export const ABOUT = "/about";
export const BLOG = "/blog";
export const CONTACT = "/contact";
export const PROJECTS = "/projects";
export const THANK_YOU = "/thank-you";
export const PURCHASE_THANK_YOU = "/p-thank-you";
export const SINGLE_POST = "/:slug";
export const REGISTER = "/register";
export const LOGIN = "/login";
export const RESET = "/reset";
export const DASHBOARD = "/dashboard";
export const GET_STARTED = "/get-started";
export const HOST_SUBSCRIPTION = "/host-subscription";
export const VIEW_TICKETS = "/helpdesk/tickets/";
export const PROFILE = "/profile";
export const UPDATE_PROFILE = "/update-profile";
export const CONTACTS = "/contacts";
export const ADD_NOTE = "/add-note";
export const EDIT_NOTE = "/edit-note";
export const NOTE_LIST = "/notes";
export const TODO = "/todo";
export const EDIT_TASK = "/edit-task";
export const ADD_TASK = "/add-task";
export const CREATE_ARTICLE = "/create-article";
export const EDIT_ARTICLE = "/edit-artice/:id";
export const SINGLE_ARTICLE = "/article/:id";
export const ARTICLES = "/articles";
